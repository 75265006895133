import { api } from '../../../../utils/url';
import request from '../../../../utils/request';
import { PrismContainerStateCollated } from './PrismContainerStateCollated';
import { PrismComputedContainerLocationStatus } from './PrismComputedContainerLocationStatus';

export const fetchPrismMilestones = async (): Promise<PrismContainerStateCollated[]> => {
  const url = api(`/prism/milestones`);

  const res = await request(url);
  return res;
};

export const validateContainerEntries = async (entries: string): Promise<Record<string, boolean>> => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ entries })
  };

  const url = api(`/prism/registrations/validate`);

  const res = await request(url, options);
  return res;
};

export interface RegisterContainersRepsonse {
  [containerNumber: string]: {
    status: string;
    entity?: PrismComputedContainerLocationStatus;
    error?: string;
  };
}

export const registerContainers = async (containerNumbers: string[]): Promise<RegisterContainersRepsonse> => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ entries: containerNumbers.map((container) => ({ container })) })
  };

  const url = api(`/prism/registrations/bulk`);

  const res = await request(url, options);
  return res;
};

export const deleteRegistration = async (id: string): Promise<void> => {
  const options = {
    method: 'DELETE'
  };

  const url = api(`/prism/registrations/${id}`);

  const res = await request(url, options);
  return res;
};

export const refreshMilestones = async (): Promise<void> => {
  const options = {
    method: 'POST'
  };

  const url = api(`/prism/milestones`);

  await request(url, options);
};
