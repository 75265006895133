import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { SelfServiceQuote, expireQuote } from '../../../../../../../../../actions/self-service-quotes';
import { Department } from '/app/components/pages/TrackingOrderDetailPage/components/TrackingOrderDetailEventCard/component';
import { getDepartmentDisplayName } from '/app/utils/resources/department';
import Button from '/app/components/shared/Button';

import './style.css';

interface ListItemProps {
  quote: SelfServiceQuote;
  departmentsById: Record<string, Department>;
}

function formatForcast(quote: SelfServiceQuote, forecastType: 'prepull' | 'chassis' | 'storage') {
  const numberOfOccurances =
    // eslint-disable-next-line no-nested-ternary
    forecastType === 'prepull'
      ? quote.forecastPrePullDays
      : forecastType === 'chassis'
        ? quote.forecastChassisDays
        : quote.forecastStorageDays;

  const costPerOccurance =
    // eslint-disable-next-line no-nested-ternary
    forecastType === 'prepull'
      ? quote.forecastPrePullCostPerDay
      : forecastType === 'chassis'
        ? quote.forecastChassisCostPerDay
        : quote.forecastStorageCostPerDay;

  const occuranceName = forecastType === 'prepull' ? 'Occurance' : 'Day';

  const numberOf = `${numberOfOccurances} ${occuranceName}${numberOfOccurances === 1 ? '' : 's'}`;
  const costDetails = numberOfOccurances === 0 ? '' : `at $${costPerOccurance} each`;

  return `${numberOf} ${costDetails}`;
}

function Row({ label, value }: { label: string; value: string | number }) {
  return (
    <div className="list-item__row">
      <span className="list-item__title">{label}:</span> {value}
    </div>
  );
}

function QuoteListItem({ quote, departmentsById }: ListItemProps) {
  const baseClassName = 'list-item';

  const [expiringQuote, setExpiringQuote] = useState<boolean>(false);
  const [quoteExpiration, setQuoteExpiration] = useState(quote.expiredAt);

  const handleExpireQuote = async (quoteId: string) => {
    setExpiringQuote(true);

    const res = await expireQuote({ quoteId });
    setQuoteExpiration(res.expiredAt);

    setExpiringQuote(false);
  };

  const expirationDate = moment(quoteExpiration).format('ddd, MMM DD, HH:mm z');
  const isExpiredClass = moment(quoteExpiration).isBefore(moment()) ? `expired` : '';

  const laneDesription = `Port of LA/LB -> ${getDepartmentDisplayName(
    departmentsById[quote.destinationDepartmentUuid]
  )} ${isExpiredClass === 'expired' ? 'EXPIRED' : ''}
  `;
  const projectedForecastTotal =
    quote.forecastChassisDays * quote.forecastChassisCostPerDay +
    quote.forecastStorageDays * quote.forecastStorageCostPerDay +
    quote.forecastPrePullDays * quote.forecastPrePullCostPerDay;

  // Remove fuel surcharge from total cost [https://drayalliance.atlassian.net/browse/PK-104]
  const projectedTotal = quote.totalCost + projectedForecastTotal - quote.fuelSurcharge;

  return (
    <div className={`${baseClassName} ${isExpiredClass}`}>
      <h3>{laneDesription}</h3>
      <div className={`${baseClassName}__overview`}>
        <div className={`${baseClassName}__column`}>
          <Row label="Forecast Total" value={`$${projectedTotal}`} />
        </div>
        <div className={`${baseClassName}__column`}>
          <Row label="Distance" value={`${quote.distance} mi`} />
          <Row label="Expires" value={`${expirationDate}`} />
        </div>
      </div>

      <div>
        <hr />
      </div>

      <div className={`${baseClassName}__details`}>
        <div className={`${baseClassName}__column`}>
          <Row label="Drayage" value={`$${quote.drayCharge}`} />
          <Row label="Fuel Surcharge" value={`$${quote.fuelSurcharge}`} />
        </div>
        <div className={`${baseClassName}__column`}>
          <Row label="Chassis Forecast" value={`${formatForcast(quote, 'chassis')}`} />
          <Row label="Pre-pull Forecast" value={`${formatForcast(quote, 'prepull')}`} />
          <Row label="Storage Forecast" value={`${formatForcast(quote, 'storage')}`} />
          <Row label="Accessorial Total" value={`$${projectedForecastTotal}`} />
        </div>
      </div>

      <div className={`${baseClassName}__actions`}>
        <Button
          className={`${baseClassName}__button`}
          label="Force Expiration"
          color="secondary"
          disabled={expiringQuote || isExpiredClass === 'expired'}
          loading={expiringQuote}
          onClick={() => handleExpireQuote(quote.uuid)}
        />

        <Link
          className={`r-b-btn ${
            isExpiredClass === 'expired' ? 'r-b-btn--disabled' : 'r-b-btn--secondary'
          } ${baseClassName}__button`}
          color="secondary"
          to="/dashboard/self-service/create-order"
        >
          Create Order
        </Link>
      </div>
    </div>
  );
}

export default QuoteListItem;
