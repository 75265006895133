export enum PrismComputedContainerLocationStatus {
  Unknown = 'UNKNOWN',
  Searching = 'SEARCHING',
  AtOrigin = 'AT_ORIGIN',
  OnWater = 'ON_WATER',
  ArrivedDestination = 'ARRIVED_AT_DESTINATION',
  Discharged = 'DISCHARGED',
  AvailableForPickup = 'AVAILABLE_FOR_PICKUP',
  Outgated = 'OUTGATED',
  Ingated = 'INGATED'
}

export const prismComputedContainerLocationStatusSelectOptions = Object.values(
  PrismComputedContainerLocationStatus
).map((status) => ({
  label: status,
  value: status
}));
