import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Component from './component';
import { logAnalyticsPageView } from '/app/utils/analytics';
import { raiseToast } from '../../shared/Toaster';
import SystemToast from '../../shared/SystemToast';
import {
  fetchPrismMilestones,
  deleteRegistration,
  refreshMilestones,
  validateContainerEntries,
  registerContainers,
  RegisterContainersRepsonse
} from './data/actions';
import { filterAndSort, SearchFilters, SortColumns, SortOrder } from './data/SearchFilters';
import { PrismContainerStateCollated } from './data/PrismContainerStateCollated';

function PrismPageConsumer() {
  /* registration state */
  const [isValidatingContainerInput, setIsValidatingContainerInput] = useState<boolean>(false);
  const [isRegisteringContainerInput, setIsRegisteringContainerInput] = useState<boolean>(false);
  const [containerValidationOutput, setContainerValidationOutput] = useState<Record<string, boolean> | null>(null);
  const [containerRegistrationOutput, setContainerRegistrationOutput] = useState<RegisterContainersRepsonse | null>(
    null
  );
  const [containerNumbersToAdd, setContainerNumbersToAdd] = useState<string>('');
  const [containerNumbersToRegisterAreStale, setContainerNumbersToAddAreStale] = useState<boolean>(true);

  /* filtering state */
  const [filters, setFilters] = useState<SearchFilters>({
    sortColumn: SortColumns.ContainerNumber,
    sortOrder: SortOrder.Asc,
    search: '',
    statuses: []
  });

  /* milestone state */
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [allMilestones, setAllMilestones] = useState<PrismContainerStateCollated[]>([]);
  const [filteredMilestones, setFilteredMilestones] = useState<PrismContainerStateCollated[]>([]);

  /* milestone handlers */
  const fetchMilestones = useCallback(async () => {
    try {
      const milestones: PrismContainerStateCollated[] = await fetchPrismMilestones();
      const filteredAndSorted = filterAndSort(milestones, filters);

      setAllMilestones(milestones);
      setFilteredMilestones(filteredAndSorted);
    } catch (err) {
      raiseToast(<SystemToast type="error" message="Error fetching prism milestones" />, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }, []);

  const handleRefresh = useCallback(async () => {
    try {
      setIsRefreshing(true);

      await refreshMilestones();
      await fetchMilestones();

      setIsRefreshing(false);
    } catch (err) {
      raiseToast(<SystemToast type="error" message="Error refreshing milestones" />, {
        position: toast.POSITION.BOTTOM_RIGHT
      });

      setIsRefreshing(false);
    }
  }, []);

  /* registration handlers */
  const updateContainerNumbersToAdd = useCallback((containerNumber: string) => {
    setContainerNumbersToAddAreStale(true);
    setContainerNumbersToAdd(containerNumber);
  }, []);

  const sendValidateContainerEntries = useCallback(async () => {
    try {
      setIsValidatingContainerInput(true);
      setContainerNumbersToAddAreStale(false);

      const reply = await validateContainerEntries(containerNumbersToAdd);

      setIsValidatingContainerInput(false);
      setContainerValidationOutput(reply);
    } catch (err) {
      setIsValidatingContainerInput(false);
      setContainerValidationOutput(null);
      raiseToast(<SystemToast type="error" message="Error adding container" />, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }, [containerNumbersToAdd]);

  const registerCurrentContainerEntries = useCallback(
    async (containerNumbers: string[]) => {
      try {
        setIsRegisteringContainerInput(true);
        setContainerRegistrationOutput(null);
        const response = await registerContainers(containerNumbers);
        setContainerRegistrationOutput(response);
        setIsRegisteringContainerInput(false);
        // setContainerNumbersToAdd('');

        await fetchMilestones();
      } catch (err) {
        setIsRegisteringContainerInput(false);
        raiseToast(<SystemToast type="error" message="Error adding container" />, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    },
    [containerNumbersToAdd]
  );

  const handleDeleteRegistration = useCallback(async (id: string) => {
    try {
      await deleteRegistration(id);
      await fetchMilestones();
    } catch (err) {
      raiseToast(<SystemToast type="error" message="Error deleting registration" />, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }, []);

  /* filtering handlers */
  const handleSortAndFilterUpdates = useCallback(
    (newFilters: SearchFilters) => {
      const filteredAndSorted = filterAndSort(allMilestones, newFilters);

      setFilters(newFilters);
      setFilteredMilestones(filteredAndSorted);
    },
    [allMilestones, filters, filteredMilestones]
  );

  const initialDataLoad = useCallback(async () => {
    fetchMilestones();
  }, []);

  useEffect(() => {
    logAnalyticsPageView('prism');
    initialDataLoad();
  }, []);

  return (
    <div>
      <Component
        /* registration */
        isValidatingContainerEntries={isValidatingContainerInput}
        isRegisteringContainerEntries={isRegisteringContainerInput}
        containerNumbersToRegister={containerNumbersToAdd}
        containerNumbersToRegisterAreStale={containerNumbersToRegisterAreStale}
        containerRegistrationValidationOutput={containerValidationOutput}
        containerRegistrationOutput={containerRegistrationOutput}
        updateContainerNumbersToAdd={updateContainerNumbersToAdd}
        validateContainerRegistrationEntries={sendValidateContainerEntries}
        registerCurrentContainerEntries={registerCurrentContainerEntries}
        deleteRegistration={handleDeleteRegistration}
        /* milestones */
        isRefreshing={isRefreshing}
        milestones={filteredMilestones}
        refreshMilestones={handleRefresh}
        /* filters */
        filters={filters}
        updateFilters={handleSortAndFilterUpdates}
      />
    </div>
  );
}

export default PrismPageConsumer;
