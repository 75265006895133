import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider, Navigate, RouteObject } from 'react-router-dom';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import {
  getJWT,
  getHUBServiceAuthToken,
  getRawHUBServiceImpersonateAuthToken,
  getRawHUBServiceAuthToken,
  isJWTValid,
  getDepartment,
  decodeToken
} from '/app/utils/auth';
import appStateManager from '/lib/react-app-state/app-state-manager';
import GlobalModal from '/app/components/shared/GlobalModal';
import DashboardLayout from '/app/components/layouts/DashboardLayout';
import AppStateRouterCache from '/app/components/shared/AppStateRouterCache';
import Toaster from '/app/components/shared/Toaster';
import SystemHeader from '../../shared/SystemHeader';
import { setAnalyticsUserId, setAnalyticsUserProperties } from '/app/utils/analytics';
import LinkOnlyToOrderDetail from '/app/components/pages/TrackingOrderDetailPage/components/LinkOnlyToOrderDetail/';
import { useAppState } from '/lib/react-app-state';
import createLoginRedirectURL from '/app/utils/create-login-redirect-url';
import TrackingOrdersPage from '../../pages/TrackingOrdersPage';
import TrackingOrderDetailPage from '../../pages/TrackingOrderDetailPage';
import AdminPage from '../../pages/AdminPage';
import SettingsPage from '../../pages/SettingsPage';
import ToolsPage from '../../pages/ToolsPage';
import { fetchAnalyticsLinks } from '/app/actions/shipper-dash-analytics';
import AnalyticsPage from '../../pages/AnalyticsPage';

import './style.css';
import ErrorBoundary from '../../shared/ErrorBoundary/component';
import { setDatadogUser } from '/app/utils/datadog';
import SelfServicePage from '../../pages/SelfServicePage/component';
import PrismPage from '../../pages/PrismPage';

// TODO: create Provider component in react-app-state to pass initial state.
const hubServiceAuthToken = getHUBServiceAuthToken();
const jwt = getJWT(hubServiceAuthToken);
const isAuthorized = isJWTValid(jwt);
const userDepartment = getDepartment(jwt);

appStateManager.onUpdate((state, statePath) => {
  if (state.auth && statePath === 'auth') {
    const jwt = getJWT(decodeToken(getRawHUBServiceAuthToken()));

    if (jwt) {
      setAnalyticsUserId(jwt.uuid);
      setAnalyticsUserProperties();
    }
  }
});

appStateManager.setState({
  auth: isAuthorized
    ? {
        jwt,
        hubServiceAuthToken
      }
    : null,
  'auth:impersonating': !!getRawHUBServiceImpersonateAuthToken(),
  userDepartment: userDepartment || null
});

export interface AppLayoutProps {}

function AppLayout() {
  const ldClient = useLDClient();
  const [analyticsInfo, setAnalyticsInfo] = useState<any>(null);
  const authState = useAppState('auth');
  const { showShipperDashSettingsTab, shipperInvoiceLink } = useFlags();

  useEffect(() => {
    const identifyUser = async () => {
      if (ldClient && jwt) {
        const { uuid, firstName, lastName, email1, departments = [] } = jwt;
        const company = departments[0] || {};
        const { name: companyName, uuid: companyUUID, type: departmentType } = company;

        setDatadogUser({
          id: jwt.uuid,
          email: jwt.email1,
          name: `${jwt.firstName} ${jwt.lastName}`
        });

        await ldClient.identify({
          key: uuid,
          firstName,
          lastName,
          email: email1,
          custom: { company: companyName, companyUUID, departmentType }
        });
      }
    };
    if (jwt) {
      setAnalyticsUserId(jwt.uuid);
      setAnalyticsUserProperties();
      identifyUser();
    }
  }, [jwt]);

  useEffect(() => {
    const getAnalyticsLinks = async () => {
      try {
        const res = await fetchAnalyticsLinks();
        setAnalyticsInfo(res);
      } catch (err) {
        setAnalyticsInfo(null);
      }
    };

    getAnalyticsLinks();

    return () => {
      setAnalyticsInfo(null);
    };
  }, []);

  const dashboardChildren: RouteObject[] = [
    {
      index: true,
      element: <Navigate to="/dashboard/tracking/orders" />
    },
    {
      path: 'tracking',
      children: [
        {
          index: true,
          element: <Navigate to="/dashboard/tracking/orders" />
        },
        {
          path: 'orders',
          children: [
            {
              index: true,
              element: <TrackingOrdersPage />
            },
            {
              path: ':orderId',
              element: <TrackingOrderDetailPage />
            }
          ]
        }
      ]
    },
    {
      path: 'admin',
      element: <AdminPage />
    },
    {
      path: 'tools',
      element: <ToolsPage />
    },
    {
      path: 'prism',
      element: <PrismPage />
    },
    {
      path: 'self-service/:subPage?',
      element: <SelfServicePage />
    },
    {
      path: 'analytics/*',
      element: <Navigate to="/dashboard" />
    }
  ];

  if (showShipperDashSettingsTab) {
    dashboardChildren.push({
      path: 'settings',
      element: <SettingsPage />
    });
  }

  if (analyticsInfo && Object.keys(analyticsInfo).length > 0) {
    const analyticsIndex = dashboardChildren.findIndex((child) => child.path === 'analytics/*');
    dashboardChildren.splice(analyticsIndex, 1);

    dashboardChildren.push({
      path: 'analytics',
      children: [
        {
          path: 'demurrage',
          element: <AnalyticsPage info={analyticsInfo} analyticsType="demurrage" title="Demurrage" />
        },
        {
          path: 'scorecard',
          element: <AnalyticsPage info={analyticsInfo} analyticsType="scorecard" title="Scorecard" />
        }
      ]
    });
  }

  const routes: RouteObject[] = [
    {
      path: '/',
      element: <Navigate to="/dashboard/tracking/orders" />,
      errorElement: <ErrorBoundary />
    },
    {
      path: '/dashboard',
      element: (
        <>
          <SystemHeader />
          <AppStateRouterCache />
          <Toaster />
          <GlobalModal />
          <DashboardLayout analyticsInfo={analyticsInfo} />
        </>
      ),
      errorElement: <ErrorBoundary />,
      children: dashboardChildren
    },
    {
      path: '*',
      element: <Navigate to="/dashboard/tracking/orders" />
    }
  ];

  if (shipperInvoiceLink) {
    routes.push({
      path: '/orders/:orderNum',
      element: <LinkOnlyToOrderDetail />
    });
  }

  const router = createBrowserRouter(routes);

  // Make sure we have an auth state before rendering the app.
  if (!authState) {
    window.location.href = createLoginRedirectURL('shipper');

    return null;
  }

  return <RouterProvider router={router} />;
}

export default AppLayout;
