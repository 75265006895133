import { PrismContainerStateCollated } from './PrismContainerStateCollated';

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum SortColumns {
  ContainerNumber = 'containerNumber',
  LocationStatus = 'containerLocationStatus',
  Carrier = 'carrierCode',
  Origin = 'originPortUnloCode',
  Destination = 'destinationPortUnloCode',
  Discharge = 'destinationDischargeDate',
  AvailForPickup = 'destinationAvailableForPickupDate',
  GateOut = 'gateOutDate',
  GateIn = 'gateInDate'
}

export interface SearchFilters {
  sortColumn: SortColumns;
  sortOrder: SortOrder;
  search: string;
  statuses: { label: string; value: string }[];
}

export const filterAndSort = (milestones: PrismContainerStateCollated[], filters: SearchFilters) => {
  const { sortColumn, sortOrder, search } = filters;

  const allColumns = Object.values(SortColumns);
  const statusFiltered = milestones.filter((milestone) => {
    if (filters.statuses.length === 0) {
      return true;
    }

    return filters.statuses.some((status) => status.value === milestone.containerLocationStatus);
  });

  const searchStringFiltered = statusFiltered.filter((milestone) => {
    for (let i = 0; i < allColumns.length; i++) {
      const column = allColumns[i];
      const columnValue = milestone[column]?.toLowerCase();

      if (columnValue && columnValue.includes(search.toLowerCase())) {
        return true;
      }
    }

    return false;
  });

  return searchStringFiltered.sort((a: PrismContainerStateCollated, b: PrismContainerStateCollated) => {
    const aValue: string = a[sortColumn] ?? '';
    const bValue: string = b[sortColumn] ?? '';

    if (sortOrder === SortOrder.Asc) {
      return aValue > bValue ? 1 : -1;
    }

    return aValue < bValue ? 1 : -1;
  });
};
