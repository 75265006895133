export function isImportOrder(order) {
  return order ? order.importExport === 'import' || order.importExport === 'graypoolImport' : null;
}

export function sortOrder(order) {
  if (order && order.legs) {
    // sort legs
    order.legs = order.legs.sort((a: any, b: any) => a.LegOrderRelations.position - b.LegOrderRelations.position);

    // sort stops on each leg
    order.legs.forEach((leg) => {
      leg.stops = leg.stops.sort(
        (a: any, b: any) =>
          // const aLegStopRelation = a.LegStopRelations.find((LegStopRelation) => LegStopRelation.legUuid === leg.uuid);

          // const bLegStopRelation = b.LegStopRelations.find((LegStopRelation) => LegStopRelation.legUuid === leg.uuid);

          a.LegStopRelations.position - b.LegStopRelations.position
      );
    });
  }
}

export function sortOrders(orders) {
  if (orders) {
    orders.forEach((order) => {
      sortOrder(order);
    });
  }
}

export function findGraypoolGroupOfOrder(order) {
  const graypoolEmptyLegGroup =
    order.legGroups && order.legGroups.find((legGroup) => legGroup.groupType === 'graypoolEmpty');

  if (graypoolEmptyLegGroup && graypoolEmptyLegGroup.groups) {
    return graypoolEmptyLegGroup.groups[0];
  }

  return null;
}

export function findPickupStopOfOrder(order) {
  const firstLeg = order && order.legs && order.legs.find((leg) => leg.LegOrderRelations.position === 0);

  if (firstLeg) {
    const firstStop = firstLeg.stops && firstLeg.stops.find((stop) => stop.departmentUuid === order.pickupAccountUuid);

    return firstStop;
  }

  return null;
}

export function findPickupStopOfOrderWithPosition(order) {
  let result: Record<string, any> = { pickupStop: undefined, stopPosition: undefined };

  const firstLeg = order && order.legs && order.legs.find((leg) => leg.LegOrderRelations.position === 0);

  if (firstLeg) {
    let stopPosition = 0;
    const pickupStop =
      firstLeg.stops &&
      firstLeg.stops.find((stop, index) => {
        stopPosition = index;
        return stop.departmentUuid === order.pickupAccountUuid;
      });

    result = { pickupStop, stopPosition };
  }

  return result;
}

export function findPickupStopInstantOfOrder(order) {
  if (order) {
    const stop = findPickupStopOfOrder(order);

    if (stop && stop.stopInstants) {
      const result = stop.stopInstants[0].type === 'departure' ? stop.stopInstants[0] : stop.stopInstants[1];

      return result;
    }
  }

  return null;
}

export function findReturnStopOfOrder(order) {
  const hasLastLeg =
    order &&
    order.legs &&
    order.legs.find((leg) => leg.LegOrderRelations.lastPosition === leg.LegOrderRelations.position);

  if (hasLastLeg) {
    const lastLeg = order.legs.find((leg) => leg.LegOrderRelations.lastPosition === leg.LegOrderRelations.position);
    const lastStop = lastLeg.stops && lastLeg.stops.find((stop) => stop.departmentUuid === order.returnAccountUuid);

    return lastStop;
  }

  return null;
}

export function findReturnStopInstantOfOrder(order) {
  if (order) {
    const stop = findReturnStopOfOrder(order);

    if (stop && stop.stopInstants) {
      const result = stop.stopInstants[0].type === 'arrival' ? stop.stopInstants[0] : stop.stopInstants[1];

      return result;
    }
  }

  return null;
}

export function findDeliveryStopInstantOfSortedOrder(order, stopInstantType = 'arrival') {
  if (order) {
    const deliveryDepartmentId = order.deliveryAccount && order.deliveryAccount.uuid;
    const { legs } = order;
    let legsIndex = legs.length;

    while (--legsIndex >= 0) {
      const leg = legs[legsIndex];
      const { stops } = leg;
      let stopsIndex = stops.length;

      while (--stopsIndex >= 0) {
        const stop = stops[stopsIndex];

        if (stop.departmentUuid === deliveryDepartmentId) {
          const result = stop.stopInstants.find((stopInstant) => stopInstant.type === stopInstantType);

          return result;
        }
      }
    }
  }

  return null;
}

export function findReturnStopInstantOfSortedOrder(order) {
  if (order && order.legs) {
    const stop = findReturnStopOfOrder(order);

    if (stop && stop.stopInstants) {
      const result = stop.stopInstants[0].type === 'arrival' ? stop.stopInstants[0] : stop.stopInstants[1];

      return result;
    }
  }

  return null;
}

export function findPickupStopInstantOfSortedOrder(order) {
  if (order) {
    const { pickupStop, stopPosition } = findPickupStopOfOrderWithPosition(order);

    if (pickupStop) {
      return pickupStop.stopInstants.find((stopInstant) =>
        stopPosition === 0 ? stopInstant.type === 'departure' : stopInstant.type === 'arrival'
      );
    }
  }

  return null;
}

export function findBackhaulLegfOfSortedOrder(order) {
  let result: Record<string, any> | null = null;

  if (order) {
    const { legs } = order;
    let legsIndex = legs.length;

    while (--legsIndex >= 0) {
      const leg = legs[legsIndex];
      const firstStop = leg.stops[0];
      const department = firstStop && firstStop.department;

      if (department && department.type === 'consignee') {
        result = leg;
        break;
      }
    }
  }

  return result;
}

export function findNonDryRunLegsFromOrder(order) {
  let result = null;

  if (order && order.legs) {
    result = order.legs.filter((leg) => leg.type !== 'dry_run');
  }

  return result;
}

export function findOrderInvoiceLineItems(order, opts) {
  if (order && order.invoices && Array.isArray(order.invoices)) {
    for (let i = 0; i < order.invoices.length; i++) {
      const invoice = order.invoices[i];
      for (let j = 0; j < invoice.lineItems.length; j++) {
        const lineItem = invoice.lineItems[j];
        if (lineItem.name === opts.name) {
          return [lineItem];
        }
      }
    }
  }

  return null;
}

export function removeDryRunLegsFromOrders(orders) {
  if (orders) {
    orders.forEach((order) => {
      const legs = findNonDryRunLegsFromOrder(order);
      order.legs = legs;
    });
  }

  return orders;
}
