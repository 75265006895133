import React, { ChangeEvent, useCallback } from 'react';
// eslint-disable-next-line import/no-unresolved
import { mkConfig, generateCsv, download } from 'export-to-csv';
import Button from '../../shared/Button/component';
import { SearchFilters, SortColumns, SortOrder } from './data/SearchFilters';
import { PrismContainerStateCollated } from './data/PrismContainerStateCollated';
import SelectDropdown from '../../shared/SelectDropdown';
import { prismComputedContainerLocationStatusSelectOptions } from './data/PrismComputedContainerLocationStatus';
import { RegisterContainersRepsonse } from './data/actions';
import DownloadIcon from '../../../../media/icons/com/save_alt-24px.svg';
import './style.css';

export interface PrismPageProps {
  /* registration */
  isValidatingContainerEntries: boolean;
  isRegisteringContainerEntries: boolean;
  containerNumbersToRegister: string;
  containerNumbersToRegisterAreStale: boolean;
  containerRegistrationValidationOutput: Record<string, boolean> | null;
  containerRegistrationOutput: RegisterContainersRepsonse | null;
  updateContainerNumbersToAdd: (containerNumber: string) => any;
  validateContainerRegistrationEntries: () => any;
  registerCurrentContainerEntries: (containerNumbers: string[]) => any;
  deleteRegistration: (id: string) => any;

  /* milestones */
  isRefreshing: boolean;
  milestones: PrismContainerStateCollated[];
  refreshMilestones: () => any;

  /* filters */
  filters: SearchFilters;
  updateFilters: (filters: SearchFilters) => any;
}

function PrismPage(props: PrismPageProps) {
  const baseClassName = 'prism-page';

  /* validation values */
  const allContainerNumbers =
    props.containerRegistrationValidationOutput && Object.keys(props.containerRegistrationValidationOutput);
  const allContainersValid =
    props.containerRegistrationValidationOutput &&
    Object.values(props.containerRegistrationValidationOutput).every((isValid) => isValid);
  const validationErrors =
    props.containerRegistrationValidationOutput &&
    Object.entries(props.containerRegistrationValidationOutput).filter((item) => !item[1]);

  /* registration values */
  const registrationErrors =
    props.containerRegistrationOutput &&
    Object.entries(props.containerRegistrationOutput)
      .filter(([_, value]) => value && value.error)
      .map(([key, value]) => ({ ...value, containerNumber: key }));

  const handleContainerChange = useCallback(
    (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      event.preventDefault();
      props.updateContainerNumbersToAdd(event.target.value);
    },
    [props.updateContainerNumbersToAdd]
  );

  const generateCsvDownload = useCallback(() => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const data = props.milestones.map((milestone) => ({
      containerNumber: milestone.containerNumber,
      status: milestone.containerLocationStatus,
      carrier: milestone.carrierCode,
      destination: milestone.destinationPortUnloCode,
      vesselETA: milestone.destinationPortVesselEstimatedArrival,
      discharge: milestone.destinationDischargeDate,
      lastFreeDay: milestone.destinationLastFreeDay,
      availForPickup: milestone.destinationAvailableForPickupDate,
      gateOut: milestone.gateOutDate,
      gateIn: milestone.gateInDate
    }));

    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }, [props.milestones]);

  const formatDate = (date: string | undefined) => {
    if (!date) return '';

    const d = new Date(date);
    return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
  };

  const handleFilterColumnClick = useCallback(
    (column: SortColumns) => () => {
      const columnChanged = props.filters.sortColumn !== column;
      const sortOrder = columnChanged || props.filters.sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
      const search = props.filters.search;

      props.updateFilters({
        sortColumn: column,
        sortOrder,
        search,
        statuses: [...props.filters.statuses]
      });
    },
    [props.filters, props.updateFilters]
  );

  const handleSearchStringChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const search = event.target.value;
      props.updateFilters({
        sortColumn: props.filters.sortColumn,
        sortOrder: props.filters.sortOrder,
        search,
        statuses: [...props.filters.statuses]
      });
    },
    [props.filters, props.updateFilters]
  );

  const handleStatusSelectionChange = useCallback(
    (selectedOptions: Array<{ value: string; label: string }>, actionType, cProps) => {
      const statuses = [...selectedOptions];

      props.updateFilters({
        ...props.filters,
        statuses
      });
    },
    [props.filters, props.updateFilters]
  );

  return (
    <div className={`${baseClassName}`}>
      <h1>Enter Containers</h1>
      <div className="prism-container-entry">
        <div className="container-validation">
          <textarea
            placeholder="Container Numbers"
            value={props.containerNumbersToRegister}
            onChange={handleContainerChange}
          >
            {props.containerNumbersToRegister}
          </textarea>
          <div className="actions">
            <Button
              label="Validate Entries"
              color="secondary"
              loading={props.isValidatingContainerEntries}
              disabled={props.isValidatingContainerEntries || props.containerNumbersToRegister === ''}
              onClick={() => props.validateContainerRegistrationEntries()}
            />
            <Button
              label="Add Container(s)"
              color="secondary"
              loading={props.isValidatingContainerEntries || props.isRegisteringContainerEntries}
              disabled={!allContainersValid || props.containerNumbersToRegisterAreStale}
              onClick={() => !!allContainerNumbers && props.registerCurrentContainerEntries(allContainerNumbers)}
            />
          </div>
        </div>
        {validationErrors && validationErrors?.length > 0 && (
          <div className="invalid-entries">
            <h3>Please Correct The Following Errors</h3>
            {validationErrors.map((item) => (
              <div key={item[0]}>{item[0]} - Invalid Container Number</div>
            ))}
          </div>
        )}

        {registrationErrors && registrationErrors?.length > 0 && (
          <div className="invalid-entries">
            <h3>Please Correct The Following Errors</h3>
            {registrationErrors.map((item) => (
              <div key={item.containerNumber}>
                {item.containerNumber} - {item.error}
              </div>
            ))}
          </div>
        )}
      </div>

      <h1>Milestones</h1>
      <div className="search">
        <input
          type="text"
          placeholder="Wildcard Match "
          value={props.filters.search}
          onChange={handleSearchStringChange}
        />
        <div className="filterSelector">
          <SelectDropdown
            id="container_status"
            options={prismComputedContainerLocationStatusSelectOptions}
            isMulti
            placeholder="Container Status"
            value={props.filters.statuses}
            onChange={handleStatusSelectionChange}
          />
        </div>
        <div className="spacer"> </div>
        <Button
          label="Refresh Milestones"
          color="primary"
          loading={props.isRefreshing}
          disabled={props.isRefreshing}
          onClick={() => props.refreshMilestones()}
        />
        <Button
          className={`${baseClassName}__download-btn`}
          label="Export Milestones"
          icon={<DownloadIcon />}
          loading={props.isRefreshing}
          disabled={props.isRefreshing}
          onClick={() => generateCsvDownload()}
        />
      </div>

      {props.milestones.length === 0 && <div>No Matching Containers Found</div>}
      {props.milestones.length > 0 && (
        <table className={`${baseClassName}__milestone`}>
          <thead>
            <th onClick={handleFilterColumnClick(SortColumns.ContainerNumber)}>Container</th>
            <th onClick={handleFilterColumnClick(SortColumns.LocationStatus)}>Status</th>
            <th onClick={handleFilterColumnClick(SortColumns.Carrier)}>Carrier</th>
            <th onClick={handleFilterColumnClick(SortColumns.Destination)}>Destination</th>
            <th onClick={handleFilterColumnClick(SortColumns.Destination)}>Vessel ETA</th>
            <th onClick={handleFilterColumnClick(SortColumns.Discharge)}>Discharge</th>
            <th onClick={handleFilterColumnClick(SortColumns.Discharge)}>Last Free Day</th>
            <th onClick={handleFilterColumnClick(SortColumns.AvailForPickup)}>Avail For Pickup</th>
            <th onClick={handleFilterColumnClick(SortColumns.GateOut)}>Gate Out</th>
            <th onClick={handleFilterColumnClick(SortColumns.GateIn)}>Gate In</th>
            <th> - </th>
          </thead>
          {props.milestones.map((milestone) => {
            let destinationPort = milestone.destinationPortUnloCode ? `${milestone.destinationPortUnloCode}` : '';
            if (milestone.destinationPortUnloCode && milestone.destinationTerminalSmdgCode) {
              const terminalSmdg = milestone.destinationTerminalSmdgCode.replace(milestone.destinationPortUnloCode, '');
              destinationPort = destinationPort.concat(` / ${terminalSmdg}`);
            }

            let gateInCodes = milestone.gateInUnloCode ? `${milestone.gateInUnloCode}` : '';
            if (milestone.gateInUnloCode && milestone.gateInSmdgCode) {
              const gatInSmdg = milestone.gateInSmdgCode.replace(milestone.gateInUnloCode, '');
              gateInCodes = gateInCodes.concat(` / ${gatInSmdg}`);
            }
            if (gateInCodes !== '') {
              gateInCodes = `(${gateInCodes})`;
            }

            return (
              <tr>
                <td>{milestone.containerNumber}</td>
                <td>{milestone.containerLocationStatus}</td>
                <td>{milestone.carrierCode} </td>
                <td>{destinationPort}</td>
                <td>{formatDate(milestone.destinationPortVesselEstimatedArrival)}</td>
                <td>{formatDate(milestone.destinationDischargeDate)}</td>
                <td>{formatDate(milestone.destinationLastFreeDay)}</td>
                <td>{formatDate(milestone.destinationAvailableForPickupDate)}</td>
                <td>{formatDate(milestone.gateOutDate)}</td>
                <td>
                  {formatDate(milestone.gateInDate)} {gateInCodes}
                </td>
                <td>
                  <Button
                    label="Delete"
                    color="secondary"
                    loading={false}
                    onClick={() => props.deleteRegistration(milestone.registrationId)}
                  />
                </td>
              </tr>
            );
          })}
        </table>
      )}
    </div>
  );
}

export default PrismPage;
